import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';

import imgs from '../../utils/images';

const Sidebar = () => {
    const location = useLocation();

    // Define a function to check if a given path is active
    const isActive = (path) => {
        return location.pathname === path;
    };

    // Define your styles for active and inactive links
    const activeStyle = {
        backgroundColor: '#2D8C9A',
        padding: '12px',
        display: 'inline-block',
        borderRadius: '3px'
    };

    const inactiveStyle = {
        padding: '12px',
        display: 'inline-block',
    };
    useEffect(() => {
        document.body.className = "client-pages";
        return () => {
            document.body.className = "";
        }
    }, []);
    return (
        <ul className="sidebar-nav">
            <li className="sidebar-item">
                <Link to="/dashboard" style={isActive('/dashboard') ? activeStyle : inactiveStyle} className="sidebar-link"><img src={imgs.dashboard_icon} alt='dashboard' /> </Link>
            </li>
        </ul>
    )
}

export default Sidebar;
