import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './components/Landing';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import RegisterSuccess from './components/layouts/RegisterSuccess';


import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';
import Navbar from './components/layouts/Navbar';
//Redux
import { Provider } from 'react-redux';
import store from './store';

import './App.css';

import AuthenticatedRoutes from './components/routing/AuthenticatedRoutes';

// import { REGISTER_SUCCESS } from './actions/types';


if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
    if (localStorage.token) {
      document.body.className = 'signin';
      return () => { document.body.className = ''; }
    }
  }, []);


  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Navbar />
          <Routes>
            <Route path='/' element={<Landing />} />
          </Routes>
          <section className=''>
            <Routes>
              <Route exact path='/register' element={<Register />} />
              <Route exact path='/login' element={<Login />} />
              <Route exact path='/register_success' element={<RegisterSuccess />} />
              <Route exact path='/*' element={<AuthenticatedRoutes />} />
            </Routes>
          </section>
        </Fragment>
      </Router>
    </Provider>
  );
}


export default App;
