import axios from 'axios';
import {
    GET_CLIENT_COUNT,
    CLIENT_COUNT_ERROR,
    GET_ALL_CLIENTS,
    ALL_CLIENTS_ERROR,
    UPDATE_CLIENT,
    CLIENT_ERROR, GET_CLIENT
} from './types';

import { setAlert } from './alert';
import { base_url } from './constants';

axios.defaults.baseURL = base_url;


export const getClientCount = () => async dispatch => {
    try {
        const res = await axios.get(`/api/admin/count_clients`);
        console.log(res);

        dispatch({ type: GET_CLIENT_COUNT, payload: res.data });
    } catch (err) {
        dispatch({
            type: CLIENT_COUNT_ERROR,
            payload: { msg: err.response }
        });
    }
}



// GET ALL CLIENTS
export const getAllClients = () => async dispatch => {
    try {
        const res = await axios.get(`/api/admin/all_clients`);
        console.log(res);
        dispatch({ type: GET_ALL_CLIENTS, payload: res.data });
    } catch (err) {
        dispatch({
            type: ALL_CLIENTS_ERROR,
            payload: { msg: err.response }
        });
    }
}

// GET CURRENT CLIENT BY ID
export const getCurrentClient = id => async dispatch => {
    try {
        const res = await axios.get(`/api/admin/client/${id}`);
        console.log(res.data);
        dispatch({ type: GET_CLIENT, payload: res.data });
    } catch (err) {
        dispatch({
            type: CLIENT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}

// Update client
export const updateClient = (id, formDataToSend) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.put('/api/admin/client/update/' + id, formDataToSend, config);
        dispatch({
            type: UPDATE_CLIENT,
            payload: res.data
        });

        dispatch(setAlert('Client`s Stripe ID Updated', 'success'));
    } catch (err) {
        const errors = err.response.data.errors;
        console.log(errors);
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({
            type: CLIENT_ERROR,
        });
    }
}

// REMOVE CLIENT TEMPORARY
export const removeClient = (id) => async dispatch => {

    try {
        const res = await axios.put('/api/admin/delete/' + id);
        dispatch({
            type: UPDATE_CLIENT,
            payload: res.data
        });
        dispatch(getAllClients());
    } catch (err) {
        const errors = err.response.data.errors;
        console.log(errors);
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({
            type: CLIENT_ERROR,
        });
    }
}