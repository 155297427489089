import React, { Fragment, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../../actions/auth';
import Alert from '../../utils/Alert';

import PropTypes from 'prop-types'


const Login = ({ login, isAuthenticated }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',

    });
    const { email, password } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onSubmit = e => {
        e.preventDefault();
        login({ email, password });
    };

    //Redirect if logged in
    if (isAuthenticated) {
        return <Navigate to='/dashboard' />;
    }

    return (
        <Fragment>
            <div className='registration'>
                <Alert />
                <div className='p-md-5 p-3'>
                    <h2 className='text-center'>Admin Login</h2>
                    <form className="form mt-5" onSubmit={e => onSubmit(e)}>
                        <div className="mb-5">
                            <label htmlFor="businessEmail" className="form-label">Email</label>
                            <input type="email" className='form-control form-control-lg' name="email" id='businessEmail' value={email} onChange={e => onChange(e)
                            } required />
                        </div>
                        <div className=" mb-5">
                            <label htmlFor='password' className="form-label">Password</label>
                            <input type="password" className="form-control form-control-lg" name="password" id='password' value={password} onChange={e => onChange(e)
                            } aria-label="Password" required />
                        </div>
                        <div className='text-center'>
                            <Link to='/' className="btn btn-custom-ouline me-4">Cancel</Link>
                            <input type="submit" className="btn btn-custom" value="Login" />
                        </div>
                        <p className='my-4 text-center'>
                            Don't have an account? <Link to='/register'>Sign Up</Link>
                        </p>
                    </form>

                </div>

            </div>
        </Fragment>
    )
}

Login.propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
};
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});
export default connect(mapStateToProps, { login })(Login); 
