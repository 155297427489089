import axios from 'axios';
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
} from './types';

import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';
import { base_url } from './constants';

axios.defaults.baseURL = base_url;

//Load Admin User
export const loadUser = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const res = await axios.get('/api/admin/auth');
        dispatch({ type: USER_LOADED, payload: res.data });
    } catch (error) {
        dispatch({ type: AUTH_ERROR });
    }
}

//REGISTER ADMIN USER
export const register = ({ firstname, lastname, email, password }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ firstname, lastname, email, password });
    try {
        const res = await axios.post('/api/admin/users', body, config);

        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data
        });
        dispatch(setAlert(res.data, 'success', 20000));

    } catch (err) {
        const errors = err.response.data.errors;
        console.log(errors);
        console.log("failed");
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({ type: REGISTER_FAIL });
    }
}

// UPDATE BUSINESS INFO
// export const updateInfo = (id, formData1) => async dispatch => {
//     console.log(formData1);
//     const config = {
//         headers: {
//             credentials: 'include',
//         }
//     }
//     const body = formData1;
//     try {
//         const res = await axios.put('/api/admin/info_update/' + id, body, config);
//         dispatch({
//             type: BUSINESS_INFO_UPDATED,
//             payload: res.data
//         });
//         dispatch(setAlert('Business information updated successfully', 'success'));
//         dispatch(loadUser());
//     } catch (err) {
//         const errors = err.response.data.errors;
//         console.log(errors);
//         if (errors) {
//             errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
//         }
//         dispatch({
//             type: BUSINESS_INFO_ERROR,
//         });
//     }
// }




//LOGIN USER
export const login = ({ email, password }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ email, password });
    try {
        const res = await axios.post('/api/admin/auth', body, config);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
        if (res.data['code'] === 400) {
            dispatch(setAlert(res.data['msg'], 'danger'));
        }
        dispatch(loadUser());
    } catch (err) {
        console.log(err);
        const errors = err.response.data.errors;
        console.log(errors);
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({ type: LOGIN_FAIL });
    }
}
//Logout / Clear Profile
export const logout = () => dispatch => {
    dispatch({ type: LOGOUT });
}