import React from 'react'
import PropTypes from 'prop-types';
import { Route, Routes, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ path, element, auth: { isAuthenticated, loading } }) => {
    return !loading && (isAuthenticated ? <Routes><Route path={path} element={element} /></Routes> : <Navigate to='/login' />);
}

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
