import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom';
import hide_pwd from '../../assets/img/hide-pwd.png'
import show_pwd from '../../assets/img/show-pwd.png'

import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { register } from '../../actions/auth';
import Alert from '../../utils/Alert';

import PropTypes from 'prop-types'


const Register = ({ setAlert, register }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setshowConfirmPassword] = useState(false);

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        password2: ''
    });
    const { firstname, lastname, email, password, password2 } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onSubmit = e => {
        e.preventDefault();

        if (password !== password2) {
            console.log("password not matched");
            setAlert('Password do not match', 'danger');
        } else {

            register({ firstname, lastname, email, password });

        }

        setFormData({ firstname: '', lastname: '', email: '', password: '', password2: '' });

    };


    const handlePwdToggle = () => {
        setShowPassword(!showPassword);
    };

    const handleConfirmPwdToggle = () => {
        setshowConfirmPassword(!showConfirmPassword);
    };

    return (
        <Fragment>
            <div className='registration'>
                <div className='p-md-5 p-3'>
                    <Alert />
                    <h2 className='text-center'>Admin Registration</h2>
                    <form className="form mt-5" onSubmit={e => onSubmit(e)}>
                        <div className="row mb-3 mb-md-5">
                            <div className="col-md-6 col-12 mb-3 mb-md-0">
                                <label htmlFor='firstname' className="form-label">First Name</label>
                                <input type="text" className="form-control form-control-lg" name='firstname' id='firstname' value={firstname} onChange={e => onChange(e)
                                } required />
                            </div>
                            <div className="col-md-6 col-12">
                                <label htmlFor='lastname' className="form-label">Last Name</label>
                                <input type="text" className="form-control form-control-lg" name='lastname' id='lastname' value={lastname} onChange={e => onChange(e)
                                } required />
                            </div>
                        </div>
                        <div className="mb-3 mb-md-5">
                            <label htmlFor="businessEmail" className="form-label">Email</label>
                            <input type="email" className='form-control form-control-lg' name="email" id='businessEmail' value={email} onChange={e => onChange(e)
                            } required />
                        </div>
                        <div className="row mb-3 mb-md-5">
                            <div className="col-md-6 col-12 mb-3 mb-md-0 position-relative">
                                <label htmlFor='password' className="form-label">Password</label>
                                <input type={showPassword ? 'text' : 'password'} className="form-control form-control-lg" name="password" id='password' value={password} onChange={e => onChange(e)
                                } aria-label="Password" required />
                                <div type="button" className="showpwd" onClick={handlePwdToggle}>
                                    {showPassword ? <img src={hide_pwd} alt="" /> : <img src={show_pwd} alt="" />}
                                </div>
                            </div>
                            <div className="col-md-6 col-12 position-relative">
                                <label htmlFor='password2' className="form-label">Confirm Password</label>
                                <input type={showConfirmPassword ? 'text' : 'password'} className="form-control form-control-lg" name="password2" id='password2' value={password2} onChange={e => onChange(e)
                                } aria-label="Confirm Password" required />
                                <div type="button" className="showpwd" onClick={handleConfirmPwdToggle}>
                                    {showConfirmPassword ? <img src={hide_pwd} alt="" /> : <img src={show_pwd} alt="" />}
                                </div>
                            </div>
                        </div>
                        <div className='text-center'>
                            <Link to='/' className="btn btn-custom-ouline me-4"  >Cancel</Link>
                            <input type="submit" className="btn btn-custom" value="Register" />
                        </div>
                        <p className='my-4 text-center'>
                            Already have an account? <Link to='/login'>Sign In</Link>
                        </p>
                    </form>
                </div>

            </div>
        </Fragment>
    )
}

Register.propTypes = {
    setAlert: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
}
const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setAlert, register })(Register); 
