import {
    GET_CLIENT_COUNT,
    CLIENT_COUNT_ERROR,
    GET_ALL_CLIENTS,
    ALL_CLIENTS_ERROR,
    GET_CLIENT,
    CLIENT_ERROR,
    UPDATE_CLIENT
} from '../actions/types';

const initialState = { clients: [], client: null, loading: true, error: {} }

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ALL_CLIENTS:
            return {
                ...state,
                clients: payload,
                loading: false
            }
        case GET_CLIENT_COUNT:
        case GET_CLIENT:
            return {
                ...state,
                client: payload,
                loading: false
            }
        case UPDATE_CLIENT:
            return {
                ...state,
                clients: [...state.clients, payload],
                loading: false
            }
        case CLIENT_COUNT_ERROR:
        case ALL_CLIENTS_ERROR:
        case CLIENT_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state;
    }

}