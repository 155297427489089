import schedule_icon from "../assets/img/57.svg"
import dashboard_icon from "../assets/img/72.svg"
import sale_icon from "../assets/img/59.svg"
import client_list from "../assets/img/61.svg"
import services_icon from "../assets/img/62.svg"
import profile_icon from "../assets/img/63.svg"
import promote_icon from "../assets/img/64.svg"
import report_icon from "../assets/img/65.svg"
import business_icon from "../assets/img/66.svg"
import support_icon from "../assets/img/67.svg"
import email_verified from "../assets/img/email_verified.svg"
import dropdown_icon from "../assets/img/dropdown-icon.png"
import clock_icon from "../assets/img/clock-icon.svg"
import appointment_icon from "../assets/img/appointment-icon.svg"
import appointment_grey_icon from "../assets/img/appointment_grey.svg"
import services_grey_icon from "../assets/img/services_grey.svg"
import edit_icon from "../assets/img/edit_icon.svg"


export default { 'schedule_icon': schedule_icon, 'dashboard_icon': dashboard_icon, 'sale_icon': sale_icon, 'client_list': client_list, 'services_icon': services_icon, 'profile_icon': profile_icon, 'promote_icon': promote_icon, 'report_icon': report_icon, 'business_icon': business_icon, 'support_icon': support_icon, 'email_verified': email_verified, 'dropdown_icon': dropdown_icon, 'clock_icon': clock_icon, "appointment_icon": appointment_icon, 'appointment_grey_icon': appointment_grey_icon, 'services_grey_icon': services_grey_icon, "edit_icon": edit_icon };


