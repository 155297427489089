import PrivateRoute from './PrivateRoute';
import Dashboard from '../admin_dashboard/Dashboard';
import React, { Fragment } from 'react';
import ClientPage from '../admin_dashboard/ClientPage';
function AuthenticatedRoutes() {
    return (
        <Fragment>
            <PrivateRoute path='/dashboard' element={<Dashboard />} />
            <PrivateRoute path='/client/id/:id' element={<ClientPage />} />

        </Fragment>
    );
}

export default AuthenticatedRoutes;