import React, { Fragment } from 'react'
import Alert from '../utils/Alert';

const Header = (props) => {
    
    return (
        <Fragment>
            <div className='serviceHeader'>
                <div className='d-flex justify-content-between p-3'>
                    <h2 className='fw-bold'>{props.heading}</h2>
                    <Alert />
                    <div>
                        
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default Header
