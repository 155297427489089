import React, { Fragment, useEffect, useState } from 'react'

import { connect } from 'react-redux';
import { logout } from '../../actions/auth';

import PropTypes from 'prop-types';
import Sidebar from '../layouts/Sidebar'
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { getClientCount, getAllClients, removeClient } from '../../actions/clients';




const customStyles = {
    header: {
        style: {
            minHeight: '56px',
        },
    },
    headRow: {
        style: {
            fontSize: '13px',
            fontWeight: 'bold',
            backgroundColor: "#F8F8F8"
        },
    },

};
const Dashboard = ({ getClientCount, getAllClients, removeClient, all_clients: { clients, client, loading } }) => {
    const columns = [

        {
            name: 'Client Name',
            selector: row => row.firstName + ' ' + row.lastName,
            sortable: true,
        },
        {
            name: 'Stripe ID',
            selector: row => row.stripe_id,
            sortable: true,
        },
        {
            name: '',
            selector: row => <Fragment><Link class="btn link-success" to={`/client/id/${row.id}`}>Edit</Link> <Link class="btn link-danger" onClick={() => handleDelete(row.id)}>Remove</Link></Fragment>,
            sortable: true,
        },

    ];

    const [allClients, setAllClients] = useState([]);

    const [clientCount, setClientCount] = useState("0");

    useEffect(() => {
        getClientCount();
    }, [getClientCount]);

    useEffect(() => {
        getAllClients()
    }, [getAllClients]);

    const handleDelete = async (id) => {
        if (id) {
            const confirmDelete = window.confirm('Are you sure you want to delete this client?');
            if (confirmDelete) {
                try {
                    await removeClient(id);
                    setAllClients(clients);
                } catch (error) {
                    console.error('Error deleting record:', error);
                }
            }
        }
    };
    useEffect(() => {
        if (client)
            setClientCount(client.cnt);
    }, [client]);

    useEffect(() => {
        setAllClients(clients);
    }, [clients]);

    return (
        <Fragment>
            <div className='wrapper'>
                <Sidebar />
                <div className='servicePage'>
                    <div className='serviceHeader'>
                        <div className='d-flex justify-content-between p-3'>
                            <h2 className='fw-400'>Dashboard</h2>
                        </div>
                    </div>
                    <section className="p-5" id="dashboard_analysis">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3">
                                    <Link to='/dashboard' className="card p-5 text-decoration-none text-dark position-relative">
                                        <img src="" alt="" width="20px" className='position-absolute card_icon' />
                                        <div className="card-body text-center">
                                            <h2>{clientCount}</h2>
                                            <p>Clients</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-md-3">
                                    <Link to="/dashboard" className="card p-5 text-decoration-none text-dark position-relative">
                                        <img src="" alt="" width="20px" className='position-absolute card_icon ' />
                                        <div className="card-body text-center">
                                            <h2>1</h2>
                                            <p>Active Clients</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-md-3"></div>
                                <div className="col-md-3"></div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className='mt-5'>
                                        <div className="py-3 "><h5 className='fw-bold'>Payment Details</h5></div>
                                        <div className='border'>
                                            <DataTable columns={columns} data={allClients} customStyles={customStyles}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >
        </Fragment >

    );
}
Dashboard.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    getClientCount: PropTypes.func.isRequired,
    getAllClients: PropTypes.func.isRequired,
    removeClient: PropTypes.func.isRequired,

}
const mapStateToProps = state => ({
    auth: state.auth,
    all_clients: state.clients
});


export default connect(mapStateToProps, { getClientCount, removeClient, getAllClients, logout })(Dashboard);
