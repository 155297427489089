export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';




//CLIENTS
export const GET_CLIENT_COUNT = 'GET_CLIENT_COUNT';
export const CLIENT_COUNT_ERROR = 'CLIENT_COUNT_ERROR';

export const GET_LATEST_CLIENTS = 'GET_LATEST_CLIENTS';
export const LATEST_CLIENTS_ERROR = 'LATEST_CLIENTS_ERROR';
export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS';
export const ALL_CLIENTS_ERROR = 'ALL_CLIENTS_ERROR';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const CLIENT_ERROR = 'CLIENT_ERROR';

export const GET_CLIENT = 'GET_CLIENT';

