import React, { Fragment, useState, useEffect } from 'react'
import Header from '../Header';
import Sidebar from '../layouts/Sidebar'
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';
import { getCurrentClient, updateClient } from '../../actions/clients';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import currencies from '../../currenciesCode.json'


const ClientPage = ({ getCurrentClient, updateClient, get_client: { client, loading } }) => {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        stripe_id: "",
        currency: "",
        currencySymbol: ""
    });
    const [selectedCurrencySymbol, setSelectedCurrencySymbol] = useState('');

    useEffect(() => {
        getCurrentClient(id);
    }, [getCurrentClient, id]);

    useEffect(() => {
        setFormData({
            stripe_id: loading || !client.stripe_id ? "" : client.stripe_id,
            currency: loading || !client.currency ? "" : client.currency,
            currencySymbol: loading || !client.currencySymbol ? "" : client.currencySymbol
        });
    }, [client, loading]);


    const { stripe_id, currency, currencySymbol } = formData;
    const onChange = e => {
        console.log(e.target.value);
        setFormData({ ...formData, [e.target.name]: e.target.value })
    };
    useEffect(() => {
        const selectedCurrency = currencies.find(currency => currency.value === formData.currency);
        setSelectedCurrencySymbol(selectedCurrency ? selectedCurrency.symbol : '');
    }, [formData.currency, currencies]);

    const onSubmit = e => {
        e.preventDefault();
        try {
            var formDataToSend = new FormData();
            formDataToSend.append('currency', formData.currency);
            formDataToSend.append('currencySymbol', selectedCurrencySymbol); // Include currencySymbol in FormData
            formDataToSend.append('stripe_id', formData.stripe_id);
            updateClient(id, formDataToSend);
        } catch (error) {
            console.log(error);
        }
       

    };


    return (
        <Fragment>
            <div className='wrapper'>
                <Sidebar />
                <div className='servicePage'>
                    <Header heading="Edit Stripe ID" />
                    <div className='createForm'>
                        <form className="form mt-5" onSubmit={e => onSubmit(e)}>
                            <div className="card">
                                <h5 className="card-header">Basic Info</h5>
                                <div className="card-body p-5">
                                    <input type="hidden" className='form-control form-control-lg ' name="id" id='id' value={id} />
                                    <div className='mb-5'>
                                        <label htmlFor='client_name' className="form-label">Client name</label>
                                        <input type="text" className='form-control form-control-lg' name="client_name" id='client_name' value={client && client.firstName} disabled />
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="stripe_id" className="form-label">Stripe ID</label>
                                        <input type="text" className='form-control form-control-lg' name="stripe_id" id='stripe_id' value={stripe_id} onChange={e => onChange(e)
                                        } />
                                    </div>
                                    <div className="mb-5">
                                        <select name="currency" id='currency' className='form-select form-control-lg' value={currency} data-value={currencySymbol} onChange={e => onChange(e)}>
                                            <option value="">Select currency</option>
                                            {currencies.map(option => (
                                                <option key={option.value} value={option.value} data-value={option.symbol}>
                                                    {option.label} - {option.symbol}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='text-center pt-4 d-flex justify-content-end'>
                                <Link to='/dashboard' className="btn btn-custom me-4"  >Cancel</Link>
                                <input type="submit" className="btn btn-custom" value="Update" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );

}

ClientPage.propTypes = {
    logout: PropTypes.func.isRequired,
    getCurrentClient: PropTypes.func.isRequired,
    // updateClient: PropTypes.func.isRequired,

}
const mapStateToProps = state => ({
    get_client: state.clients
});

export default connect(mapStateToProps, { updateClient, getCurrentClient, logout })(ClientPage);
