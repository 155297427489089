import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo.png'
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';

import PropTypes from 'prop-types';
const Navbar = ({ auth: { isAuthenticated, user, loading }, logout }) => {
    const [fName, setfName] = useState('');
    const [lName, setlName] = useState('');
    useEffect(() => {
        if (user) {
            localStorage.setItem('firstNaam', user.first_name);
            localStorage.setItem('lastNaam', user.last_name);
        }

    }, [user]);

    useEffect(() => {
        let firstName = localStorage.getItem('firstNaam');
        let lastName = localStorage.getItem('lastNaam');
        if (isAuthenticated && user) {
            firstName = firstName[0].toUpperCase();
            lastName = lastName[0].toUpperCase();
            setfName(firstName);
            setlName(lastName);
        }
    }, [user, isAuthenticated]);
    const authLinks = (
        <nav className="navbar navbar-expand-lg navbar-dark bg-light">
            <div className="container-fluid">
                <Link to='/dashboard' className="navbar-brand"><img src={logo} alt='logo' width="90" /></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 align-items-center  mb-lg-0">
                        <li className="nav-item">
                            <Link onClick={logout} className="nav-link" to="#!"> <i className='fas fa-sign-out-alt'></i> Logout</Link>
                        </li>
                        <li className="nav-item ms-3">
                            <div className='profile-img'>
                                <span className='p-2 fs-5'>{`${fName}${lName}`}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
    const guestLinks = (
        <nav className='navbar'>
            <Link to="https://booqy.in">  <img src={logo} alt='logo' /></Link>
        </nav>
    );
    return (

        <div>
            {!loading && (<Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>)}
        </div>
    )
}
Navbar.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(mapStateToProps, { logout })(Navbar);
